import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const Wrapper = styled.div`
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  background-color: ${colors.header_bg};
  border-bottom: 1px solid ${colors.header_border};
  height: 68px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: fixed;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: inherit;
  align-items: center;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  
  svg {
    stroke: ${colors.dark_grey};
  }
`;

export const Username = styled.p`
  font-size: 15px;
  margin-right: 10px;
  font-weight: 400;
  color: ${colors.nav_label_active};

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchPart = styled.div`
  margin-left: 36px;
`;

export const VerticalSpacer = styled.div`
  width: ${p => p.width && p.width};
`;

export const DropdownMenu = styled.div`
  box-shadow: 0 1px 6px #00000029;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 78px;
  right: 65px;
  width: 175px;
  opacity: ${props => (props.visible ? "1" : "0")};
  height: ${props => (props.visible ? "auto" : "0")};
  padding: ${props => (props.visible ? "0px" : "0px")};
  transition: all 0.2s ease-out;
  overflow: hidden;
`;

export const LanguageItem = styled.div`
  display: flex;
  margin: 5px 0;
  padding: 5px;
  border-radius: 6px;
  transition: 0.2s ease-out;
  text-decoration: none;
  font-weight: 400;
  font-size: 15px;
  color: ${colors.nav_label};
  cursor: pointer;
  
  &:hover {
    background-color: ${colors.very_very_light_grey};
  }
  
  svg {
    stroke: ${colors.light_purple};
  }

  &.active, &.active > div {
    background-color: ${colors.nav_bg};
    color: ${colors.nav_label_active};
  }
`;

export const IconBox = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconTagBox = styled.div`
  color: ${colors.very_light_purple};
  display: flex;
  width: 20px;
  transform: ${p => p.rotate
          ? "rotate(180deg)"
          : "none"
  };
`;

export const Label = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 5px;
  font-size: 15px;
  color: ${colors.nav_label_active};
  transition: 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover, &.active {
    color: ${colors.nav_label_active};
  }
`;

export const Logout = styled.div`
  display: flex;
  margin: 5px 0;
  padding: 5px;
  border-radius: 6px;
  transition: 0.2s ease-out;
  text-decoration: none;
  font-weight: 400;
  font-size: 15px;
  color: ${colors.nav_label};
  cursor: pointer;

  &:hover {
    background-color: ${colors.very_very_light_grey};
  }

  svg {
    stroke: ${colors.light_purple};
  }
`;

