import styled from "styled-components";
import { colors } from "../../helpers/style/constants";

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 51px;
  height: 51px;
  color: ${colors.marjory_logo_letter};
  font-family: "Jost";
  border-radius: 8px;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.marjory_logo_bg};
`;

export const Marjory = styled.div`
  margin-left: 12px;
  font-weight: bold;
  font-family: "Vollkorn";
  font-size: 26px;
`;

export const Logo = styled.img`
  width: 160px;
  margin-left: 10px;
`;