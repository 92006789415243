import logo from "../../images/marjory-logo.svg";
import * as sc from "./style";

export default function MarjoryLogo() {
  return (
    <sc.Wrapper>
      <sc.Container>
        <sc.Logo src={logo} alt="marjory" />
      </sc.Container>
    </sc.Wrapper>
  );
}
